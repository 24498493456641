import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types';
import { Layout, Menu, Col, Row, Popover, Divider, Drawer, Alert, Space, Badge, message, List, Avatar as AntAvatar, Modal } from 'antd';
import { Icon, Button, ToggleButton, Avatar, IconButton, Loader, DevBlock } from 'Common/components';
import { dateToMongo, dateToCanada, mongoToDate } from 'Common/scripts/Functions';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { logoutUser } from 'Store/saga.actions';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { DorForm } from 'Modules/Dors/components';
import DorDetails from 'Modules/Dors/dor_details';
import { defaultDateTimeFormat } from 'configs';

const RECORD = loader('src/Graphql/dors/activeDor.graphql');
const ALERTS_QUERY = loader('src/Graphql/alerts/alertsQuery.graphql');
const ALERT_VIEWED = loader('src/Graphql/alerts/alertStatusToggle.graphql');

const { Header } = Layout;

const ActiveDOR = () => {
    const [createDorForm, set_createDorForm] = useState(false);
    const [showDOR, set_showDOR] = useState(false);
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)

    const [activeDor, dor_results] = useLazyQuery(RECORD,
        // { variables: { id: match?.params?.site_id } }
    );

    useEffect(() => {
        if (dor_results.called) return;
        fetchDor();
    }, [])

    const fetchDor = async () => {
        
        setBusy(true);
        let results = await activeDor({ variables: { status: "open" } }).then(r => (r?.data?.activeDor))
        setBusy(false);

        if (results && results.error) {
            setError(results?.error?.message);
            return false;
        }

        setData(results);
    }

    const onUpdate = async() => {
        fetchDor()
    }

    if (dor_results.loading) return <Loader loading={true} />
    if (error) return <Alert message={error} showIcon type='error' />
    // if (!data) return null;

    if (!data) {
        return <>
            <div>No active DOR <Button onClick={() => set_createDorForm(true)} size="small" shape="round">Create Now</Button></div>
            <DorForm open={createDorForm}
                onUpdate={() => onUpdate()}
                onClose={() => set_createDorForm(false)}
            />
        </>
    }


    return (<>
        <Button onClick={() => set_showDOR(!showDOR)} icon={<Icon icon="newspaper" />} size="small" shape="round">DOR</Button>

        <Drawer title="DOR" placement='top' height={"100%"} footer={false} visible={showDOR} onClose={() => set_showDOR(!showDOR)}>
            {showDOR && <DorDetails data={data} onUpdate={setData} />}
        </Drawer>
    </>)
}

const AvatarPop = props => {
    let name = props.ep_admin.user.name || props.ep_admin.user.email;

    return (
        <div style={{ width: '250px' }}>
            <Row>
                <Col flex="90px"><Avatar size={80}>{String(name[0]).toUpperCase()}</Avatar></Col>
                <Col flex="auto">
                    <Space>
                        <Link to='/admin/profile'>Edit Profile</Link>
                        <Divider />
                        {/* <Button onClick={() => props.doLogout()} type="danger" block>Logout</Button> */}
                        <Button onClick={() => props.logoutUser()} type="danger" block>Logout</Button>
                    </Space>
                </Col>
            </Row>
            
            {/* <Link to='/admin/profile'>Edit Profile</Link>
            <Divider />
            <Button onClick={()=>props.doLogout()} type="danger" block>Logout</Button> */}
        </div>
    )
}

const Alerts = memo(function AlertsContainer(props){
    const [busy, setBusy] = useState(false)
    const [showDrawer, set_showDrawer] = useState(false)
    const [newAlerts, set_newAlerts] = useState(null)
    const [shwoMessage, set_shwoMessage] = useState(false)

    const [alertsQuery, alerts_results] = useLazyQuery(ALERTS_QUERY,
        // { variables: { id: match?.params?.site_id } }
    );

    const [alertStatusToggle, alerts_viewed_results] = useMutation(ALERT_VIEWED);

    useEffect(() => {
        // if (alerts_results.called) console.log("alerts_results.called: ", alerts_results.called)
        if (alerts_results.called || newAlerts) return;

        fetchData(props)

    }, [alerts_results])

    useEffect(() => {
        document.body.addEventListener('alert-received', onAlertReceived);
      return () => {
          document.body.removeEventListener('alert-received', onAlertReceived);
      };
    }, [props])

    const fetchData = async () => {
        return;
        setBusy(true)

        let variables = {
            first: 50,
            after: 0,
            filter: JSON.stringify({})
        }
        let resutls = await alertsQuery({ variables, fetchPolicy: 'network-only' })
            .then(r => (r?.data?.alertsQuery))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Unabel to pull alerts" } }
            })
        setBusy(false);

        if (resutls.error) {
            message.error(resutls.error.message);
            return;
        }

        set_newAlerts(resutls);
    }

    const onAlertReceived = () => {
        fetchData();
    }

    const toggleStatus = async (item) => {
        alertStatusToggle({ variables: { id: item._id } }).then(r => {
            set_newAlerts({
                ...newAlerts,
                edges: newAlerts.edges.map(o => ((o._id == r._id) ? r : o)),
            })
        })
    }

    const preview = (item) => {
        if (item.status == 'new') toggleStatus(item)
        set_shwoMessage(item);
    }

    return (<>
        <Badge count={(newAlerts && newAlerts?.edges?.filter(o => (o.status == 'new'))?.length) || 0}>
            {busy
                ? <Loader loading={true} />
                : <div onClick={() => set_showDrawer(!showDrawer)} className='alertButton'><Icon icon="bell" /></div>
            }
        </Badge>

        <Drawer bodyStyle={{ padding: 0 }} footer={false}
            title={`Alerts (${newAlerts && newAlerts?.edges?.filter(o => (o.status == 'new'))?.length || '0'})`} visible={showDrawer} placement='right' onClose={() => set_showDrawer(false)} width={'400px'}>
            {!(newAlerts && newAlerts.totalCount > 0) && <Alert message="No alerts" type='warning' />}

            {(showDrawer && newAlerts && newAlerts.totalCount > 0) && <>
                <List
                    itemLayout="horizontal"
                    dataSource={newAlerts && newAlerts.edges}
                    renderItem={(item) => {

                        let icon = 'circle'
                        if (item.type == 'email') icon = 'envelope';
                        if (item.type == 'error') icon = 'exclamation';
                        if (item.type == 'message') icon = 'comment-dots';

                        return (<List.Item className={`alert-list-item ${item.status == 'new' ? 'new' : ''}`}>
                            <Row gutter={[0, 0]} align="middle" style={{ width: "100%" }}>
                                <Col span={24} align="right" style={{ fontSize: "12px", color: "#48494A" }}>{mongoToDate(shwoMessage.created_at).format(defaultDateTimeFormat)}</Col>
                                <Col span={24}><IconButton onClick={() => toggleStatus(item)} icon={icon} /> <b onClick={() => preview(item)} className='a'>{item.subject}</b></Col>
                                {/* <div>{item.status}</div> */}
                                {item.body && <Col span={24}><div className="_ellipsis" style={{ height: "50px", overflow: "hidden", display: "inline-block", textOverflow: "ellipsis" }}>
                                    {item.body}
                                </div></Col>}
                            </Row>
                        </List.Item>)
                    }}
                />
            </>}
        </Drawer>

        <Modal visible={shwoMessage !== false} title={false} footer={false} onCancel={() => set_shwoMessage(false)}>
            {shwoMessage && <>
                <Row style={{ color: "#48494A" }}>
                    <Col span={12}>{shwoMessage.source}</Col>
                    <Col span={12} align="right">{mongoToDate(shwoMessage.created_at).format(defaultDateTimeFormat)}</Col>
                </Row>
                <hr />
                <div><b>{shwoMessage.subject}</b></div>
                <div>{shwoMessage.body}</div>
            </>}
        </Modal>
    </>)
})

const TopMenu = ({ menu, style }) => {
    if (!menu) return <div> </div>;

    let activeMenuItem = menu.findIndex(o => o.path == window.location.pathname)
    // let activeMenuItem = findIndexByObjVal(menu, 'path', window.location.pathname);
    if (!activeMenuItem) activeMenuItem = 0;

    return (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[`${activeMenuItem}`]} style={style || {}}>
            {menu.filter(o=>(!o.hide)).map((item, i) => {
                return <Menu.Item key={i}><Link to={item.menuPath || item.path}>{item.title}</Link></Menu.Item>
            })}
        </Menu>
    )
}

// var pacific = moment.tz("US/Pacific");
// var canada = pacific.clone().tz("Canada/Eastern");

const _LayoutHeader = props => {
    let name = props.ep_admin.user.name || props.ep_admin.user.email;
    let ca_time = mongoToDate();
    // let utc_time = moment_tz.utc();

    return (<>
        <Header className="site-layout-header" style={{ padding:'0 10px 0 0' }}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Row>
                    <Col><div style={{ color: 'white', textAlign: "center", fontWeight: 'bold', fontSize: '2em' }}>A-List Portal</div></Col>
                    <Col style={{ color: "white", marginLeft: "20px" }}>
                        CA time: {ca_time.format('dddd YYYY-MM-DD HH:mm')} 
                        {/* - UTC time: {utc_time.format('dddd YYYY-MM-DD HH:mm')}  */}
                    </Col>
                    <Col flex="auto"><TopMenu menu={props.menu} style={{ whiteSpace: "normal", flex: 'auto' }} /></Col>
                </Row>
                
                
                <Row gutter={[10]} align='middle'>
                    <Col><ActiveDOR /></Col>
                    <Col style={{ marginTop:"5px" }}><Alerts /></Col>
                    <Col>
                        <Popover content={()=><div><AvatarPop {...props} /></div>} 
                            title={<div>{String(name)}
                                {process.env.NODE_ENV == 'development' && <div style={{ fontSize: "10px" }}>{props?.ep_admin?.user?.acc_type}</div>}
                            </div>} trigger="click" placement="bottomRight">
                            <div style={{ marginRight: '0px' }}><Avatar>{String(name[0]).toUpperCase()}</Avatar></div>
                            {/* <div style={{ marginRight: '10px' }}><span style={{ marginRight: '5px', color:'white' }}>{name}</span> <Avatar>{String(name[0]).toUpperCase()}</Avatar></div> */}
                        </Popover>
                    </Col>
                </Row>
            </div>
        </Header>
    </>)
}

_LayoutHeader.propTypes = {
    onToggleClick: PropTypes.func,
    menu: PropTypes.array,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}


const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin)
        return ({ ep_admin });
    }
const mapDispatchToProps = (dispatch, ownProps) => ({
        // doLogout: (payload) => dispatch(doLogout(payload)),
        logoutUser: (payload) => dispatch(logoutUser(payload)),
    })
export const LayoutHeader = connect(mapStateToProps, mapDispatchToProps)(_LayoutHeader);
  